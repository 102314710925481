import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bot Week in San Francisco started out well with the RASA Developer Summit on September 24th, 2019. 25 speakers from all over the world came out to give talks on chatbot use cases, challenges in creating conversational assistants, and updates on the latest research in Conversational AI.`}</p>
    <p>{`Here are the top innovations and takeaways from the event.`}</p>
    <h3>{`1. `}<strong parentName="h3">{`Find the perfect image`}</strong></h3>
    <p>{`Adobe demoed their new natural language search that allows users to find specific pictures from their huge database just by speaking a few key words. You can ask it for “more” or “less” of an element or specific placements of objects. This allows designers to narrow their search much more quickly.`}</p>
    <h3>{`2. `}<strong parentName="h3">{`Learn another language through a chatbot`}</strong></h3>
    <p>{`You can learn languages in school, but most of the time those courses teach you how to say things through rote memorization, not how to react. `}<a parentName="p" {...{
        "href": "https://octesoft.com/"
      }}>{`Octesoft`}</a>{` is working on a chatbot that will help you learn another language and teach you how things are said in different contexts. This method of learning gives a student a more flexible understanding and broader usage of the new language.`}</p>
    <h3>{`3. `}<strong parentName="h3">{`Insurance powered by AI bots`}</strong></h3>
    <p><a parentName="p" {...{
        "href": "https://www.lemonade.com/"
      }}>{`Lemonade`}</a>{` created one of the most advanced insurance chatbots in the industry. Maya, their consumer-facing chatbot, can help a user file an insurance claim in record time and can process videos, photos, and text.`}</p>
    <h3>{`4. `}<strong parentName="h3">{`Learning through interaction improves dialogue`}</strong></h3>
    <p>{`Supervised pre-training can be a very powerful tool for your chatbot. However, there will still be a lot of errors since the pre-data won’t line up perfectly with actual interactions. User teaching, when the human corrects mistakes and gives feedback within the chatbot experience, `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2019/09/10/customer-service-training-data/"
      }}>{`will help overcome these errors`}</a>{`.`}</p>
    <h3>{`5. `}<strong parentName="h3">{`Dialogue quality is important`}</strong></h3>
    <p>{`The dialogue quality of a chatbot can have a large impact on how well it achieves a goal. It should only give as much information as needed, be truthful and relevant, and keep the conversation concise.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p">{`Dashbot is an analytics platform for conversational interfaces that enables enterprises to increase satisfaction, engagement, and conversions through actionable insights and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support DialogFlow, Alexa, Google Assistant, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      